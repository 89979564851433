<template>
<div class="download">
  <div class="container">
    <div class="download-section">
<div class="titles">
  <h1 class="secondary">دانلود اپلیکیشن</h1>

  <p>
    تجربه سریع تری از معامله ارز دیجیتال با
    اپلیکیشن
    <span class="secondary">هوشمند ارتباطات </span>
    <span class="yellow">بامداد</span>
  </p>
</div>
<div class="btns">
  <button>
    <img src="../../assets/bazar-logo.png" alt="">
    <span>دریافت از</span>
    <img src="../../assets/bazar.png" alt="">
  </button>
  <button>
    <img src="../../assets/myketlogo.png" alt="">
    <span>دریافت از</span>
    <h2> مایکت</h2>
  </button>
  <button>
    <img src="../../assets/sibapp.svg" alt="">
    <div>
      <span>دریافت نسخه ios از </span>
      <img src="../../assets/sibapptext.svg" alt="">
    </div>
  </button>
</div>

    </div>
    <div class="svg">
      <img src="../../assets/iphone.svg" alt="">
        
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Download"
}
</script>

<style lang="scss" scoped>
h1{
  font-size: clamp(24px, 1.5vw, 36px);
}
.container{
  display:flex;
  justify-content: space-between;
  gap:1em;
  flex-wrap: wrap;

  .download-section{
    flex:1.2;
    //justify-content: center;
  }
}

.svg{
  img{
    width: 100%;
    height: 100%;
  }
}


.download-section{
  display: flex;
  flex-direction: column;
  gap: 5em;
  p{
    max-width: 280px;
  }

  .titles{
    display: flex;
    flex-direction: column;
    gap: 36px;
    margin-top: 10%;
  }
}

.btns{
display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  button{
    max-width: 224px;

    flex:1;
    height: 68px;
    background: linear-gradient(180deg, #F5F4F2 0%, #FDFDFD 100%);
    box-shadow: -1px 3px 9px rgba(192, 222, 234, 0.42);
    border-radius: 8px;
    &:first-child , &:nth-child(2){
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
//span{
//  font-size:12px;
//}
    }

    &:last-child{
      display: flex;
      justify-content: center;
      align-items: center;
      div{
        max-width: 60%;
      }
    }
  }
}

@media(max-width: 500px){
  .titles{
    margin-top: 1rem;
  }
  .download , .btns{
    flex-direction: column;

    gap: .5rem;
  }
  .btns{
    button{
      align-self: center;
    }
    button:last-child{
      align-self: flex-end;
    }
    button:first-child{
      align-self: flex-start;
    }
  }

}
</style>