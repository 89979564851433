<template>
  <div class="card">
    <div class="khali"></div>
<div class="image-container">
  <img :src="data.image" alt="" >
</div>
    <div class="card-body">
      <div class="header">
        <h3 class="secondary">{{data.title}}</h3>
        <p>{{data.date}}</p>
      </div>
      <div class="content">
        <p>{{data.content}}</p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "CommentCard",
  props:['data']
}
</script>

<style lang="scss" scoped>
.card{
  position: relative;
  width: 100%;
  max-width: 483px;
  min-height: 300px;

}

.image-container{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  top:0;
  width: 100%;
  height: 100%;
  min-width: 140px;
  min-height: 140px;
  max-width: 140px;
  max-height: 140px;
  right: calc(50% - 60px);
  border:30px double #85928A;
  border-radius: 50%;

img{
  max-width: 120px;
}


}
.khali{
  min-height: 100px;
}
.card-body{
  transition: 1s all ease-in-out;
  background: #F0EEEA;
  border-radius: 8px;
 display: flex;
  flex-direction: column;
  padding: 1.2rem;
  gap: 18.5px;
}
//img{
//  position: absolute;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  align-self: center;
//  top: 20px;
//  width: 100%;
//  height: 100%;
//  min-width: 140px;
//  min-height: 140px;
//  max-width: 140px;
//  max-height: 140px;
//
//  right: calc(50% - 80px);
//
//}
.header{
  display: flex;
  justify-content: space-between;
}
</style>