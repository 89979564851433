<template>
  <div class="carousel">
    <div class="container">
      <h1 class="secondary">آخرین مطالب وبلاگ</h1>
      <vueper-slides
          class="no-shadow"
          :visible-slides="4"
          :rtl="true"
          :gap="0"
          :bullets="false"
          slide-multiple
          :slide-ratio="1 /4"
          autoplay
          duration="1500"
          :infinite="true"
          :dragging-distance="200"
          :breakpoints="{ 1042: { visibleSlides: 2, slideMultiple: 1 } , 722:{
        visibleSlides: 1, slideMultiple: 1 ,
        slideRatio: 1/2
      }}">
        <vueper-slide
            class="vueper-image"
            v-for="(slide, i) in slides"
            :key="i"
            :image="slide.image"
        >

        </vueper-slide>
      </vueper-slides>
    </div>
  </div>
</template>
<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: "Carousel",
  components: { VueperSlides, VueperSlide },
  data(){
    return{
      slides:[{
        image: require('@/assets/post-1.png'),
        title:'شیبا یا دوج‌کوین؟'
      ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
      },
        {
          image: require('@/assets/post-2.png'),
          title:'بیت کوین در خطر سقوط!'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-3.png'),
          title:'شیبا یا دوج‌کوین؟'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-1.png'),
            title:'سودمند ترین کوین ها'
                ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-2.png'),
          title:'بیت کوین در خطر سقوط!'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-3.png'),
          title:'شیبا یا دوج‌کوین؟'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-1.png'),
          title:'سودمند ترین کوین ها'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },

        {
          image: require('@/assets/post-2.png'),
          title:'بیت کوین در خطر سقوط!'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-3.png'),
          title:'شیبا یا دوج‌کوین؟'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-1.png'),
          title:'سودمند ترین کوین ها'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },

        {
          image: require('@/assets/post-2.png'),
          title:'بیت کوین در خطر سقوط!'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-3.png'),
          title:'شیبا یا دوج‌کوین؟'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-1.png'),
          title:'سودمند ترین کوین ها'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },

        {
          image: require('@/assets/post-2.png'),
          title:'بیت کوین در خطر سقوط!'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-3.png'),
          title:'شیبا یا دوج‌کوین؟'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-1.png'),
          title:'سودمند ترین کوین ها'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },

        {
          image: require('@/assets/post-2.png'),
          title:'بیت کوین در خطر سقوط!'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-3.png'),
          title:'شیبا یا دوج‌کوین؟'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },

        {
          image: require('@/assets/post-2.png'),
          title:'بیت کوین در خطر سقوط!'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-3.png'),
          title:'شیبا یا دوج‌کوین؟'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-1.png'),
          title:'سودمند ترین کوین ها'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },

        {
          image: require('@/assets/post-2.png'),
          title:'بیت کوین در خطر سقوط!'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-3.png'),
          title:'شیبا یا دوج‌کوین؟'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },
        {
          image: require('@/assets/post-1.png'),
          title:'سودمند ترین کوین ها'
          ,content:'لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. '
        },








      ]
    }
  }
}

</script>

<style lang="scss" scoped>
h1{
  font-size: clamp(24px, 1.5vw, 36px);
}
.container{
  display: flex;
  flex-direction: column;
  gap: 4.5em !important;
}
.secondary{
  align-self: center;
}
.carousel{

  display: flex;

  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;

  align-items: center;


  background-size:cover;

  .container{
    max-width: 1440px;
    width: 100%;
    //backdrop-filter: blur(200px);
    min-height: 449px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-wrap: wrap;
    align-items: center;


    &::before {
      content: '';
      border-radius: 4px;
      position: absolute;
      z-index: -1;
      width: 100%;
      max-width: 100%;
      left: 28px;
      height: 438px;
      background-color: transparent;
      background-image: linear-gradient( #19196F 0%, #FEBC00 100%);
      filter: blur(20px);

      transition: 0.5s ease;
    }
  }
}
</style>
<style lang="scss">
.no-shadow{
  width: 100%;
  height: 100%;

}
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--next, .vueperslides__arrows--outside .vueperslides__arrow--prev {
  right: auto;
  left: 2px;
  >svg{
    transform: scale(0.5);
  }

  .vueper-image{

  }
}
.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--prev, .vueperslides__arrows--outside .vueperslides__arrow--next {
  left: auto;
  right: 2px;
}

.vueperslide, .vueperslide__image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 291.3px 383px;

}

</style>

<style lang="scss" scoped>
@media screen and (max-width: 500px) {

  .carousel{
    min-height: 200px;
    .container{
      min-height: 200px;
      &:before{
        display: none;
      }
    }
  }

  .vueperslide, .vueperslide__image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 95% 100%;

  }
}
</style>