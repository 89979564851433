<template>
  <div class="advert">
    <div class="logo">
      <img src="../../assets/advert.png" alt="">
    </div>
    <div class="text">
      <h2 class="secondary">خرید آسان، معامله امن</h2>

      <p>لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. </p>
    </div>
  </div>
</template>

<script>
export default {
name:'advert'
}
</script>

<style lang="scss" scoped>
.advert{
  padding: 5%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap !important;
  .logo ,.text{
    flex:1;

  }
.text{
  display: flex;
  flex-direction: column;
  gap: 10%;
}
}
@media(max-width: 500px){
  .advert{
    flex-direction: column;
    .logo{
      margin-bottom: 10%;
    }
  }
  img{
    max-width: 250px;
  }
}
</style>