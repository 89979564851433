<template>
  <div class="banner">
    <div class="left-row">
      <div class="texts">
        <h1 class="yellow">هوشمند ارتباطات بامداد</h1>
        <h2 class="secondary">معامله آسان ارز دیجیتال</h2>
      </div>
      <div class="status">
        <div class="item">
          <span class="numbers secondary">

            <IcountUp :delay="1500" :endVal="totaltrad" :options="options" />

            <!-- {{ $toLocal(totaltrad) }} -->
          </span>
          <div class="subject">
            <img src="../../assets/Icons/done.svg" alt="" width="20" />
            <span class="regular"> معامله انجام شده </span>
          </div>
        </div>
        <div class="item">
          <span class="numbers secondary">
<!--            <IcountUp :delay="1000" :endVal="tradprice" :options="options" />-->

             {{ $toLocal(tradprice, 0) }}
          </span>
          <div class="subject">
            <img src="../../assets/Icons/tradeAmount.svg" alt="" width="20" />
            <span class="regular"> حجم معاملات (تومان) </span>
          </div>
        </div>
        <div style="border: none" class="item">
          <span class="numbers secondary">
            <IcountUp :delay="2300" :endVal="userscount" :options="options" />

            <!-- {{ $toLocal(userscount) }} -->
          </span>
          <div class="subject">
            <img src="../../assets/Icons/activeUsers.svg" alt="" width="20" height="20" />
            <span class="regular"> کاربران فعال </span>
          </div>
        </div>
      </div>
      <div class="start">
        <div class="input-container">
          <img src="@/assets/Icons/phone.svg" alt="" />
          <input type="text" @input="$p2e" placeholder="شماره موبایل" />
        </div>
        <button class="btn-primary" @click.prevent="$router.push({name:'RegisterMobile'})">شروع کن</button>
      </div>
    </div>
    <div class="svg">
      <globe :coinsTether="coinsTether" />
    </div>
  </div>
</template>


<script>
import IcountUp from "vue-countup-v2";
import globe from "@/components/HomePage/globe";

export default {
  name: "Banner",
  props:['coinsTether'],
  components: { globe, IcountUp },
  data() {
    return {
      userscount: 0,
      tradprice: 0,
      totaltrad: 0,
      delay: 1000,

      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
    };
  },
  methods: {
    onReady(instance, CountUp) {
      const that = this;
      instance.update(that.endVal + 100);
      console.log(CountUp);
    },

    async getInfo() {
      const res = await this.$axios("trades/market-info-home");
      this.userscount = res.baseDTO.userCount;
      this.tradprice = res.baseDTO.tradesValue;
      this.totaltrad = res.baseDTO.tradesAmount;
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style lang="scss" scoped>
.left-row {
  // width: 100%;
}
#Union_2 {
  display: none;
}

svg {
  width: 100%;
  height: 100%;
}

h1 {
  font-size: clamp(32px, 2.5vw, 48px);
  font-weight: 700;
}
h2 {
  font-size: clamp(24px, 1.5vw, 32px);
  font-weight: 400;
}
.start {
  display: flex;
  height: 48px;
  gap: 16px;
  button {
    width: 100%;
    max-width: 138px;
  }
  .input-container {
    border-radius: 8px 8px 0px 0px;
    display: flex;
    height: 48px;
    align-items: center;
    padding: 15px;
    flex: 2;
  }
}
.banner {
  display: flex;
  align-items: center;
  gap: 5%;

  .text {
  }
}
</style>

<style lang="scss" scoped>
.left-row {
  display: flex;
  flex-direction: column;
  row-gap: 42px;
  // flex:2;
}
.item {
  height: 100%;
  max-height: 83px;
  min-width: 137px;
  align-items: center;
  display: flex;
  flex-direction: column;
  // row-gap: 40px;
  padding: 0 18px;
  border-left: 1px solid #facd23;
  .numbers {
    //color: var(--primary-dark);
    font-weight: 700;
    font-size: clamp(22px, 3vw, 28px);
  }
  .subject {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }
}
.status {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}
.texts {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
}
.status-container {
  padding: 40px 120px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  &::before {
    content: " ";
    background: var(--red);
    filter: blur(400px);
    opacity: 0.1;
    filter: drop-shadow(0 0 0.75rem var(--red));
    box-shadow: 0px 0px 100px 100px var(--red);
    background-size: cover;
    background-repeat: repeat-x;
    position: absolute;
    height: 300px;
    border-radius: 50%;
    width: 10%;
    right: 300px;
    top: 100px;
  }
}
@media only screen and(max-width:1000px) {
  .img-container {
    display: none;
  }
  .status {
    flex-direction: column;
    row-gap: 20px;
  }
  .item {
    border-left: 1px solid #facd23 !important;
  }
  .status-container {
    padding: 20px;
    &::before {
      content: unset !important;
    }
  }
}

@media (max-width: 700px) {
  .banner {
    flex-direction: column;
  }
}

//max-height: 494px;
</style>