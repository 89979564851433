<template>
  <div class="steps">
    <div class="level">
      <div class="steps-container" v-for="(step,index) in steps" :key="index">
        <img :src='require("../../assets/" + step.icon + ".png")' alt="">
        <!--   <img src="../../assets/step-one.png" alt="">-->
        <img src="" alt="">
        <div class="text-titles">
          <h3 class="secondary">{{ step.title }}</h3>
          <p>{{ step.text }}</p>
        </div>

      </div>
    </div>
    <div class="text">
      <div class="title">
        <h2 class="secondary">
          مراحل معامله
        </h2>
        <p>لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره.
          لورم ایپسوم یه متنه برای اینکه نشون بدیم اینجا یه متن تصادفی قرار می گیره و هیچ معنا و اعتبار خاصی نداره. </p>
      </div>
      <div class="btns">
        <button class="btn-primary" @click.prevent="$router.push('/Dashboard/advancetrade')">شروع معامله</button>
        <button class="btn" @click.prevent="$router.push('/guide')">مشاهده راهنما</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Steps",
  data() {
    return {
      steps: [
        {
          'title': "ثبت نام",
          'text': "وارد کردن اطلاعات خود در کمتر از چند دقیقه",
          'icon': "step-one",
        },
        {
          'title': "احراز هویت",
          'text': "دسترسی به تمام امکانات سایت",
          'icon': "step-two",
        },
        {
          'title': "ثبت سفارش و معامله",
          'text': "ثبت سفارش و معامله ارز دیجیتال",
          'icon': "step-three",
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.btn{
  color: var(--secondary);
  transition: all 500ms ease;
  &:hover{
    background: transparent;
    color: var(--secondary);
    border: 1px solid var(--secondary);
    border-radius: 4px;
    // padding: 10px 20px;
    font-size: clamp(12px, 1.5vw, 16px);
    font-weight: bold;
    cursor: pointer;

  }
}
h2{
  font-size: 1.8rem;
}
.steps {
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;
  padding: 2em !important;
  .level{
    flex:1.2;
  }
  .text{
    flex:1.2;
  }
}

.level {
  display: flex;
  flex-direction: column;
  gap: 1.2em;
}

.steps-container {
  display: flex;
  gap: 16px;
}
.text-titles{
  display: flex;
  flex-direction: column;
  gap: 5px;

}
.text{
  display: flex;
  flex-direction: column;
  gap: 3.8em;
  //justify-content: center;
}
.title{
  max-width: 582px;
  display: flex;
  flex-direction: column;
  gap: 1.3em;
}

.btns{
  display: flex;
  gap: 1.5em;
  justify-content: flex-start;
  button{
    flex:1;
    max-width: 220px;
    height: 44px;
  }
}
@media(max-width: 500px){
  .steps{
    flex-direction: column;
    padding: .5rem;
  }
  .level{
    flex:1;
  }
}
</style>