<template>
  <div class="market-container">

    <div class="title">
<div class="right">
  <h2> نگاه کلی به بازار </h2>
  <span> در ۲۴ ساعت گذشته </span>
</div>


      <a v-if="$route.name !== 'Market'" @click.prevent="$router.push('/market')" href="/market" class="secondary"> مشاهده همه کوین ها </a>
    </div>
    <div class="table">
      <div class="loader" v-if="loading">
        <spinner-loading />
      </div>

      <table>
        <thead>
        <tr>
          <td style="text-align:start;"> رمز ارز </td>
          <td class="remove2"> قیمت لحظه ای </td>
          <td> تغییرات </td>
          <td class="remove"> حجم </td>
          <td style="text-align:center;" class="remove"> نمودار </td>
          <td style="text-align:end;display:flex;justify-content:center">
            <div class="pricebtns">
              <button @click="toman=true" :class="{'select':toman}" class="toman"> تومان </button>
              <button @click="toman=false" :class="{'select':!toman}" class="tether"> تتر </button>
            </div>
          </td>
        </tr>
        </thead>
        <tbody>
        <tr @click.prevent="" class="coin-row hover" v-for="(coin,index) in coins.slice(0,8)" :key="index">
          <td>
            <div class="coin">
              <img :src="'/coins/'+$coin2Snake[coin.relatedCoin]+'.png'" alt="">
              <div class="unit remove2">
                                    <span class="faunit">
                                        {{$coinLabel[coin.relatedCoin]}}
                                    </span>
                <span class="enunit">
                                        {{coin.relatedCoin}}
                                    </span>
              </div>
            </div>
          </td>
          <td class="remove2">
            {{$toLocal(coin.lastPrice,(toman ? 0:2))}}
          </td>
          <td>
                            <span :class="coin.last24HourChange > 0 ? 'success rotate':'fail'">
                                {{$toLocal(coin.last24HourChange,2)}}%
                                <inline-svg :class="coin.last24HourChange > 0 ? 'rotate':'red'" :src="require('../../assets/Icons/arrow.svg')" />
                            </span>
          </td>
          <td class="remove"> {{$toLocal(coin.last24HourVolume,$decimal[$coin2Snake[coin.relatedCoin]])}} </td>
          <td class="remove h50">
            <MarketChartApex :data="coin.lastWeekData" :option="coin.last24HourChange > 0 ? true : false" />
          </td>
          <td>
            <div class="td-btn">
              <button @click.prevent="goto(coin.relatedCoin)" class="btn-secondary-outline submit"> معامله </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import MarketChartApex from '../tools/MarketChartApex.vue'
import SpinnerLoading from "@/components/tools/Spinner";
export default {
  name:'Market',
  components:{
    SpinnerLoading,
    MarketChartApex
  },
  props:['coinsToman','coinsTether','loading'],
  data() {
    return {
      toman:false,
      coinn:{
        buyPrice: 1759047.192,
        id: null,
        last24HourChange: 0.62,
        last24HourHighestPrice: 0,
        last24HourOrders: 0,
        last24HourTrades: 0,
        last24HourVolume: 0,
        lastPrice: 1752912,
        lastPriceOfYesterday: 0,
        lastTradeCreatedDateTime: null,
        lastWeekData: [100,200,10000,1200,4500,6700,10,40,1],
        lastWeekHighestPrice: 0,
        marketType: "LITE_COIN_TOMAN",
        maxPrice: 1792700,
        meanPriceOfLast24Hour: 0,
        minPrice: 1708224,
        relatedCoin: "LTC",
        sellPrice: 1746776.808,
      }
    }
  },
  computed:{
    coins(){
      let coinss
      if(this.toman){
        coinss=this.coinsToman
      }else{
        coinss=this.coinsTether.filter(a => a.relatedCoin !== 'USDT')
      }
      return coinss
    }
  },
  methods:{
    goto(coin){
let selectedCoin =this.$coin2Snake[coin]
      if(this.toman){
        this.$router.push(`/advancetrade/TOMAN/${selectedCoin}`)
      }

      this.$router.push(`/advancetrade/TETHER/${selectedCoin}`)



    }
  }
}
</script>

<style lang="scss" scoped>
.loader{
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  z-index: 3;
}
.table{
  position: relative;
}
.coin-row:hover{
  background: #F0F0F5;
  box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
  border-radius: 8px;
  cursor: pointer;
}
.enunit{
  color: var(--a-hover) !important;
  font-weight: 400 !important;
  font-size: clamp(10px,1vw,12px) !important;
}
.marketsend{
  color: var(--primary);
  align-self: flex-end;
  margin-left: 100px;
}
.h50{
  height: 50%;
}
.td-btn{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  button{
    width: 100%;
    max-width: 130px;
  }
}
.coin{
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  padding:10px;
  img{
    width: 36px;
    height: 36px;
  }
  .unit{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
}
.select{
  color: var(--secondary) !important;
  background: #DEDEDE !important;

}
.pricebtns{
  display: flex;
  flex-direction: row;
  padding: 6px;
  width: 100%;
  height: 48px;
  box-shadow: 0px 0px 2px rgba(140, 150, 153, 0.34);
  border-radius: 4px;
  background: #F8F8F8;
  button{
    transition: 1s ease all;
    color: var(--black);
    font-weight: 500;
    font-size: clamp(10px,1vw,12px);
    border-radius: 4px;
     width: 100% !important;
     max-width: 80px !important;
    background: transparent;
  }
}
.submit{
  height: 38px;
}
.table{
  height: 100%;
  min-height: 700px;
  background: #F8F8F8;
  padding: 12px 14px;
  border-radius: 8px;
  width: 100%;
  table{
    border-collapse: collapse;
    background: transparent;
    width: 100%;
    tr{
      border-bottom: 1px solid #F0F0F0;
      height: 76px;
    }
    thead{
      height: 37px;
      td{
        text-align: start;
        font-weight: 700;
        font-size: clamp(14px,2vw,16px);
        color: var(--gray-light);
      }
    }
  }
}
.title{
  display: flex;
  justify-content: space-between;

.right{
  display: flex;
  align-items: center;
  gap: 4px;
  h2{
    font-weight: 700;
    color: var(--secondary);
    font-size: clamp(16px,2vw,20px);
  }
  span{
    font-weight: 700;
    font-size: clamp(12px,2vw,14px);
    color: var(--gray-light);
  }
}
  //display: flex;
  flex-direction: row;
  width: 100%;
  //justify-content: start;
  align-items: center;
  column-gap: 8px;
}
.market-container{
  //padding: 40px 120px;

  border-radius: 8px;
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 36px;
  justify-content: center;
  //background: url('../../assets/bubble/charts.png');
  background-repeat: no-repeat;
  background-size: 100% 80%;
  background-position: center;
}
@media only screen and(max-width:1000px){
  .market-container{
    //padding: 20px;
    align-items: center;
    justify-content: center;
  }
  .remove{
    display: none;
  }
}
@media only screen and(max-width:800px){
  .remove2{
    display: none !important;
  }
}
</style>