<template>
  <div class="usersComment">
    <h1 class="secondary">کاربران درباره ما چه می گویند؟</h1>
    <div class="content">
      <img id="user_1" src="../../assets/user-1.png" alt="">
      <img id="user_2" src="../../assets/user-2.png" alt="">
      <img id="user_3" src="../../assets/user-3.png" alt="">
      <img id="user_4" src="../../assets/user-4.png" alt="">

      <CommentCard class="comments" :data="users[activeIndex]" />

    </div>
  </div>
</template>

<script>

import CommentCard from "@/components/HomePage/CommentCard";
import {Loop} from "@/Lib/reuseableFunction";
export default {
  name: "UsersComments",
  components: {CommentCard},
  data() {
    return {
      data: '',
      loop:'',
      activeIndex:0,
      users:[
        {
          image: require("@/assets/Icons/fuser-3.png"),
          title: "زهراگودرزی",
          content: 'میتونم بگم که این سایت از بهترین و امن ترین های معاله ارز دیجیتاله. اموزش های به روز وبلاگشون به اطلاعات من در این زمینه خیلی کمک کرد.',
          date: "۱۳۹۷/۰۲/۱۲",
        },
        {
          image: require("@/assets/Icons/fuser-1.png"),
          title: "فاطمه رضایی",
          content: 'سرعت در این سایت فوق العاده بود واقعا باورم نمی شد که اینقدر سریع بتونم معاملاتم رو انجام بدم',
          date: "۱۳۹۷/۰۲/۱۲",
        },
        {
          image: require("@/assets/Icons/fuser-2.png"),
          title: "مهسا حمیدی",
          content: 'خیلی از این که در این سایت فعالیت می کنم خوشحالم واقعا تونستم سود بسیار زیادی کسب کنم',
          date: "۱۳۹۷/۰۲/۱۲",
        },
        {
          image: require("@/assets/Icons/fuser-3.png"),
          title: "ژاله احمدی",
          content: 'بسیار ممنون ازین که 24 ساعته پاسخگو هستید.',
          date: "۱۳۹۷/۰۲/۱۲",
        },
      ]

    }

  },
  methods: {






    changeData() {
      let users = [
        {
          image: require("@/assets/Icons/fuser-2.png"),
          title: "زهراگودرزی",
          content: 'میتونم بگم که این سایت از بهترین و امن ترین های معاله ارز دیجیتاله. اموزش های به روز وبلاگشون به اطلاعات من در این زمینه خیلی کمک کرد.',
          date: "۱۳۹۷/۰۲/۱۲",
        },
        {
          image: require("@/assets/Icons/fuser-3.png"),
          title: "فاطمه رضایی",
          content: 'سرعت در این سایت فوق العاده بود واقعا باورم نمی شد که اینقدر سریع بتونم معاملاتم رو انجام بدم',
          date: "۱۳۹۷/۰۲/۱۲",
        },
        {
          image: require("@/assets/Icons/fuser-2.png"),
          title: "مهسا حمیدی",
          content: 'خیلی از این که در این سایت فعالیت می کنم خوشحالم واقعا تونستم سود بسیار زیادی کسب کنم',
          date: "۱۳۹۷/۰۲/۱۲",
        },
        {
          image: require("@/assets/Icons/fuser-2.png"),
          title: "ژاله احمدی",
          content: 'بسیار ممنون ازین که 24 ساعته پاسخگو هستید.',
          date: "۱۳۹۷/۰۲/۱۲",
        },
      ];
      if(this.activeIndex < users.length - 1) {
        this.activeIndex++;
      }else{
  this.activeIndex = 0;}







    }
  },
    mounted() {
this.loop = new Loop(this.changeData,5000,'usersComment');
this.loop.start();
    },

  }


</script>

<style lang="scss" scoped>
.comments{
  transition:all ease .5s ;
}
h1 {
  font-size: clamp(24px, 1.5vw, 36px);
  align-self: center;
}

.usersComment {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background-image: url("../../assets/users.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 500px;
}

#user_1, #user_2, #user_3, #user_4 {
  position: absolute;

}

#user_1 {
  top: 20%;
  left: 10%;
}

#user_2 {
  top: 30%;
  right: 10%;
}

#user_3 {
  top: 50%;
  left: 10%;
}

#user_4 {
  top: 70%;
  right: 25%;
}

@media (max-width: 768px) {
  .usersComment {
    padding: 1rem;
  }
  #user_1, #user_2, #user_3, #user_4 {
    display: none;

  }
}
</style>