<template>
    <div class="homepage-container">

<div class="container">
            <Banner :coinsTether="coinsTether" />
<!--        <CoinsSlider :coinsToman="coinsToman" :coinsTether="coinsTether"/>-->
  <CoinsCarousel :coinsToman="coinsToman" :coinsTether="coinsTether" :loading="loading"/>
        <Advert/>
        <MarketView :coinsToman="coinsToman" :coinsTether="coinsTether" :loading="loading"/>
  <Steps/>
  <Blogs/>
</div>
      <UsersComments/>
<Download/>
    </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
let metaUpdated='no'
import Banner from './Banner.vue';
// import CoinsSlider from './CoinsSlider.vue';
import Advert from './Advert.vue';
import MarketView from './MarketView.vue';
import Steps from "@/components/HomePage/Steps";
import Blogs from "@/components/HomePage/Blogs";
import UsersComments from "@/components/HomePage/UsersComments";
import Download from "@/components/HomePage/Download";
import CoinsCarousel from "@/components/HomePage/CoinsCarousel";

    export default {
      metaInfo(){
return{
  title:'خانه',
  titleTemplate: 'به سایت خرازی خوش آمدید',
  htmlAttrs: {
    lang: 'en',
    amp: undefined // "amp" has no value
  },
  afterNavigation () {
    metaUpdated = 'yes'
  }
}
      },
    name: "HomePage",
    components: {CoinsCarousel, Download, UsersComments, Blogs, Steps, Banner,
      // CoinsSlider,
      Advert, MarketView, },
      data() {
        return {
          coinsToman:[],
          coinsTether:[],
          loading:false,
          pricetype:'TOMAN',
        }
      },
      methods:{
        async getCoins() {
          this.state.loading = true
          this.loading=true;
          const res = await this.$axios.get('/trades/market-info',{
            params: {
              weeklyDataIncluded: true,
            }
          })
          this.coinsToman = res.content.filter(a => a.marketType.includes('TOMAN'))
          this.coinsTether = res.content.filter(a => a.marketType.includes('TETHER'))
         this.loading=false;
        },
      },
      mounted(){
        this.getCoins();
      }
}
</script>

<style lang="scss" scoped>
.container{
  padding:7% 0 !important;
display: flex;
  flex-direction:column;
  gap: 50px;

}
@media (max-width:500px){
    .container{
      padding: 90px 0px;
        gap: 20px;
    }
}
</style>